import { default as indexcJyTZpwLG1Meta } from "/var/www/control.barkar.apartments/front/pages/index.vue?macro=true";
import { default as _91_46_46_46all_938K3ZUkcovdMeta } from "/var/www/control.barkar.apartments/front/pages/[...all].vue?macro=true";
import { default as _91hash_93qqwVU4ynQpMeta } from "/var/www/control.barkar.apartments/front/pages/auth/email-verify-[id]/[hash].vue?macro=true";
import { default as one_45time_45sign_45inE4Z7YW4ARAMeta } from "/var/www/control.barkar.apartments/front/pages/auth/one-time-sign-in.vue?macro=true";
import { default as sign_45in428F4Xjk9UMeta } from "/var/www/control.barkar.apartments/front/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upLaaAAO8IteMeta } from "/var/www/control.barkar.apartments/front/pages/auth/sign-up.vue?macro=true";
import { default as indext1pezinrviMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/accounts/index.vue?macro=true";
import { default as indexwA3ENOFxX6Meta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/buildings/index.vue?macro=true";
import { default as indexVRXk09yXOqMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/details/index.vue?macro=true";
import { default as addPwi8mJIo43Meta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/add.vue?macro=true";
import { default as _91id_93RH3uEHuMrxMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/edit/[id].vue?macro=true";
import { default as indexQIgOyW629ZMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/index.vue?macro=true";
import { default as indexsvgtslOkXoMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/index.vue?macro=true";
import { default as indexQth8qX1SnMMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/locations/index.vue?macro=true";
import { default as indexOR7LotA7IvMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/places/index.vue?macro=true";
import { default as indexzHUWSrhKJkMeta } from "/var/www/control.barkar.apartments/front/pages/control/accommodations/rules/index.vue?macro=true";
import { default as indexu820wNIt2GMeta } from "/var/www/control.barkar.apartments/front/pages/control/bookings/index.vue?macro=true";
import { default as index43na0JeQhqMeta } from "/var/www/control.barkar.apartments/front/pages/control/calendar/index.vue?macro=true";
import { default as configurationZnIQ2L0UV7Meta } from "/var/www/control.barkar.apartments/front/pages/control/check-in/configuration.vue?macro=true";
import { default as indexuzSGkuDHTHMeta } from "/var/www/control.barkar.apartments/front/pages/control/clients/index.vue?macro=true";
import { default as indexKZAGIiQBqrMeta } from "/var/www/control.barkar.apartments/front/pages/control/devices/index.vue?macro=true";
import { default as indexnnQ4lPQv2EMeta } from "/var/www/control.barkar.apartments/front/pages/control/employees/index.vue?macro=true";
import { default as indexLLLCejvLPlMeta } from "/var/www/control.barkar.apartments/front/pages/control/expense/advertisings/index.vue?macro=true";
import { default as indexqOahqr0DmUMeta } from "/var/www/control.barkar.apartments/front/pages/control/expense/cleanings/index.vue?macro=true";
import { default as index3bYxgiTUTeMeta } from "/var/www/control.barkar.apartments/front/pages/control/expense/communals/index.vue?macro=true";
import { default as indexgPTH8QqOg0Meta } from "/var/www/control.barkar.apartments/front/pages/control/expense/dry-washings/index.vue?macro=true";
import { default as indexHTiEhJg88OMeta } from "/var/www/control.barkar.apartments/front/pages/control/expense/expenses/index.vue?macro=true";
import { default as index990wMYE1OOMeta } from "/var/www/control.barkar.apartments/front/pages/control/expense/subrents/index.vue?macro=true";
import { default as indexcAY7uYRpH6Meta } from "/var/www/control.barkar.apartments/front/pages/control/index.vue?macro=true";
import { default as indexdk2kjyccfkMeta } from "/var/www/control.barkar.apartments/front/pages/control/owners/index.vue?macro=true";
import { default as indexP7XJusnV35Meta } from "/var/www/control.barkar.apartments/front/pages/control/payments/index.vue?macro=true";
import { default as online_45paymentsOUlCndJXbcMeta } from "/var/www/control.barkar.apartments/front/pages/control/payments/online-payments.vue?macro=true";
import { default as tax_45systemQkIcuzvhBJMeta } from "/var/www/control.barkar.apartments/front/pages/control/payments/tax-system.vue?macro=true";
import { default as indexHc29x1AjSYMeta } from "/var/www/control.barkar.apartments/front/pages/control/rates/rate-plans/index.vue?macro=true";
import { default as indexPePK9Gz9YIMeta } from "/var/www/control.barkar.apartments/front/pages/control/rates/restriction-plans/index.vue?macro=true";
import { default as indexmtpPW6weRaMeta } from "/var/www/control.barkar.apartments/front/pages/control/reviews/index.vue?macro=true";
import { default as index3p7QQoeM3wMeta } from "/var/www/control.barkar.apartments/front/pages/control/schedule/index.vue?macro=true";
import { default as backup7z73jwmEEFMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/backup.vue?macro=true";
import { default as communal_45typeZBzjHKgV9dMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/communal-type.vue?macro=true";
import { default as content0gGdIlrRsPMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/content.vue?macro=true";
import { default as contribution_45request9cWUR8XWCtMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/contribution-request.vue?macro=true";
import { default as expense_45kindCpS0mf2dLOMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/expense-kind.vue?macro=true";
import { default as indexCcPVKah8NrMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/index.vue?macro=true";
import { default as languagesJhBYNuO0E3Meta } from "/var/www/control.barkar.apartments/front/pages/control/settings/languages.vue?macro=true";
import { default as pageskyKo2PGg5sMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/pages.vue?macro=true";
import { default as place_45typesmxROIAmJXgMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/place-types.vue?macro=true";
import { default as service_45typesFrQfCVlwACMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/service-types.vue?macro=true";
import { default as sources5d4jITwBNRMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/sources.vue?macro=true";
import { default as translationRWwoHldMNsMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/translation.vue?macro=true";
import { default as xml_45googlez3P695sK4dMeta } from "/var/www/control.barkar.apartments/front/pages/control/settings/xml-google.vue?macro=true";
import { default as index5TxptM4U2SMeta } from "/var/www/control.barkar.apartments/front/pages/control/statistic/index.vue?macro=true";
import { default as index9ctGm5rpi4Meta } from "/var/www/control.barkar.apartments/front/pages/control/tasks/index.vue?macro=true";
import { default as controli5pW3yFvpXMeta } from "/var/www/control.barkar.apartments/front/pages/control.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexcJyTZpwLG1Meta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "all___en",
    path: "/en/:all(.*)*",
    component: () => import("/var/www/control.barkar.apartments/front/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___uk",
    path: "/uk/:all(.*)*",
    component: () => import("/var/www/control.barkar.apartments/front/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___ru",
    path: "/ru/:all(.*)*",
    component: () => import("/var/www/control.barkar.apartments/front/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-id-hash___en",
    path: "/en/auth/email-verify-:id()/:hash()",
    meta: _91hash_93qqwVU4ynQpMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/email-verify-[id]/[hash].vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-id-hash___uk",
    path: "/uk/auth/email-verify-:id()/:hash()",
    meta: _91hash_93qqwVU4ynQpMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/email-verify-[id]/[hash].vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-id-hash___ru",
    path: "/ru/auth/email-verify-:id()/:hash()",
    meta: _91hash_93qqwVU4ynQpMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/email-verify-[id]/[hash].vue").then(m => m.default || m)
  },
  {
    name: "auth-one-time-sign-in___en",
    path: "/en/auth/one-time-sign-in",
    meta: one_45time_45sign_45inE4Z7YW4ARAMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/one-time-sign-in.vue").then(m => m.default || m)
  },
  {
    name: "auth-one-time-sign-in___uk",
    path: "/uk/auth/one-time-sign-in",
    meta: one_45time_45sign_45inE4Z7YW4ARAMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/one-time-sign-in.vue").then(m => m.default || m)
  },
  {
    name: "auth-one-time-sign-in___ru",
    path: "/ru/auth/one-time-sign-in",
    meta: one_45time_45sign_45inE4Z7YW4ARAMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/one-time-sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___en",
    path: "/en/auth/sign-in",
    meta: sign_45in428F4Xjk9UMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___uk",
    path: "/uk/auth/sign-in",
    meta: sign_45in428F4Xjk9UMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___ru",
    path: "/ru/auth/sign-in",
    meta: sign_45in428F4Xjk9UMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-up___en",
    path: "/en/auth/sign-up",
    meta: sign_45upLaaAAO8IteMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-up___uk",
    path: "/uk/auth/sign-up",
    meta: sign_45upLaaAAO8IteMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-up___ru",
    path: "/ru/auth/sign-up",
    meta: sign_45upLaaAAO8IteMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: controli5pW3yFvpXMeta?.name,
    path: "/en/control",
    meta: controli5pW3yFvpXMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/control.vue").then(m => m.default || m),
    children: [
  {
    name: "control-accommodations-accounts___en",
    path: "accommodations/accounts",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-buildings___en",
    path: "accommodations/buildings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/buildings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-details___en",
    path: "accommodations/details",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/details/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-add___en",
    path: "accommodations/groups/add",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/add.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-edit-id___en",
    path: "accommodations/groups/edit/:id()",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups___en",
    path: "accommodations/groups",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations___en",
    path: "accommodations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-locations___en",
    path: "accommodations/locations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-places___en",
    path: "accommodations/places",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/places/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-rules___en",
    path: "accommodations/rules",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "control-bookings___en",
    path: "bookings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-calendar___en",
    path: "calendar",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "control-check-in-configuration___en",
    path: "check-in/configuration",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/check-in/configuration.vue").then(m => m.default || m)
  },
  {
    name: "control-clients___en",
    path: "clients",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "control-devices___en",
    path: "devices",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "control-employees___en",
    path: "employees",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-advertisings___en",
    path: "expense/advertisings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/advertisings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-cleanings___en",
    path: "expense/cleanings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/cleanings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-communals___en",
    path: "expense/communals",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/communals/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-dry-washings___en",
    path: "expense/dry-washings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/dry-washings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-expenses___en",
    path: "expense/expenses",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-subrents___en",
    path: "expense/subrents",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/subrents/index.vue").then(m => m.default || m)
  },
  {
    name: "control___en",
    path: "",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/index.vue").then(m => m.default || m)
  },
  {
    name: "control-owners___en",
    path: "owners",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/owners/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments___en",
    path: "payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-online-payments___en",
    path: "payments/online-payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/online-payments.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-tax-system___en",
    path: "payments/tax-system",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/tax-system.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-rate-plans___en",
    path: "rates/rate-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/rate-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-restriction-plans___en",
    path: "rates/restriction-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/restriction-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-reviews___en",
    path: "reviews",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "control-schedule___en",
    path: "schedule",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-backup___en",
    path: "settings/backup",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/backup.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-communal-type___en",
    path: "settings/communal-type",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/communal-type.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-content___en",
    path: "settings/content",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/content.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-contribution-request___en",
    path: "settings/contribution-request",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/contribution-request.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-expense-kind___en",
    path: "settings/expense-kind",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/expense-kind.vue").then(m => m.default || m)
  },
  {
    name: "control-settings___en",
    path: "settings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-languages___en",
    path: "settings/languages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/languages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-pages___en",
    path: "settings/pages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/pages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-place-types___en",
    path: "settings/place-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/place-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-service-types___en",
    path: "settings/service-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/service-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-sources___en",
    path: "settings/sources",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/sources.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-translation___en",
    path: "settings/translation",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/translation.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-xml-google___en",
    path: "settings/xml-google",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/xml-google.vue").then(m => m.default || m)
  },
  {
    name: "control-statistic___en",
    path: "statistic",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/statistic/index.vue").then(m => m.default || m)
  },
  {
    name: "control-tasks___en",
    path: "tasks",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/tasks/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: controli5pW3yFvpXMeta?.name,
    path: "/uk/control",
    meta: controli5pW3yFvpXMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/control.vue").then(m => m.default || m),
    children: [
  {
    name: "control-accommodations-accounts___uk",
    path: "accommodations/accounts",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-buildings___uk",
    path: "accommodations/buildings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/buildings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-details___uk",
    path: "accommodations/details",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/details/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-add___uk",
    path: "accommodations/groups/add",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/add.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-edit-id___uk",
    path: "accommodations/groups/edit/:id()",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups___uk",
    path: "accommodations/groups",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations___uk",
    path: "accommodations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-locations___uk",
    path: "accommodations/locations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-places___uk",
    path: "accommodations/places",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/places/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-rules___uk",
    path: "accommodations/rules",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "control-bookings___uk",
    path: "bookings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-calendar___uk",
    path: "calendar",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "control-check-in-configuration___uk",
    path: "check-in/configuration",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/check-in/configuration.vue").then(m => m.default || m)
  },
  {
    name: "control-clients___uk",
    path: "clients",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "control-devices___uk",
    path: "devices",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "control-employees___uk",
    path: "employees",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-advertisings___uk",
    path: "expense/advertisings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/advertisings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-cleanings___uk",
    path: "expense/cleanings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/cleanings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-communals___uk",
    path: "expense/communals",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/communals/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-dry-washings___uk",
    path: "expense/dry-washings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/dry-washings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-expenses___uk",
    path: "expense/expenses",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-subrents___uk",
    path: "expense/subrents",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/subrents/index.vue").then(m => m.default || m)
  },
  {
    name: "control___uk",
    path: "",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/index.vue").then(m => m.default || m)
  },
  {
    name: "control-owners___uk",
    path: "owners",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/owners/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments___uk",
    path: "payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-online-payments___uk",
    path: "payments/online-payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/online-payments.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-tax-system___uk",
    path: "payments/tax-system",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/tax-system.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-rate-plans___uk",
    path: "rates/rate-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/rate-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-restriction-plans___uk",
    path: "rates/restriction-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/restriction-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-reviews___uk",
    path: "reviews",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "control-schedule___uk",
    path: "schedule",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-backup___uk",
    path: "settings/backup",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/backup.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-communal-type___uk",
    path: "settings/communal-type",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/communal-type.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-content___uk",
    path: "settings/content",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/content.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-contribution-request___uk",
    path: "settings/contribution-request",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/contribution-request.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-expense-kind___uk",
    path: "settings/expense-kind",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/expense-kind.vue").then(m => m.default || m)
  },
  {
    name: "control-settings___uk",
    path: "settings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-languages___uk",
    path: "settings/languages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/languages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-pages___uk",
    path: "settings/pages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/pages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-place-types___uk",
    path: "settings/place-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/place-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-service-types___uk",
    path: "settings/service-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/service-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-sources___uk",
    path: "settings/sources",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/sources.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-translation___uk",
    path: "settings/translation",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/translation.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-xml-google___uk",
    path: "settings/xml-google",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/xml-google.vue").then(m => m.default || m)
  },
  {
    name: "control-statistic___uk",
    path: "statistic",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/statistic/index.vue").then(m => m.default || m)
  },
  {
    name: "control-tasks___uk",
    path: "tasks",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/tasks/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: controli5pW3yFvpXMeta?.name,
    path: "/ru/control",
    meta: controli5pW3yFvpXMeta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/control.vue").then(m => m.default || m),
    children: [
  {
    name: "control-accommodations-accounts___ru",
    path: "accommodations/accounts",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-buildings___ru",
    path: "accommodations/buildings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/buildings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-details___ru",
    path: "accommodations/details",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/details/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-add___ru",
    path: "accommodations/groups/add",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/add.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups-edit-id___ru",
    path: "accommodations/groups/edit/:id()",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-groups___ru",
    path: "accommodations/groups",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations___ru",
    path: "accommodations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-locations___ru",
    path: "accommodations/locations",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-places___ru",
    path: "accommodations/places",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/places/index.vue").then(m => m.default || m)
  },
  {
    name: "control-accommodations-rules___ru",
    path: "accommodations/rules",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/accommodations/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "control-bookings___ru",
    path: "bookings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-calendar___ru",
    path: "calendar",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "control-check-in-configuration___ru",
    path: "check-in/configuration",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/check-in/configuration.vue").then(m => m.default || m)
  },
  {
    name: "control-clients___ru",
    path: "clients",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "control-devices___ru",
    path: "devices",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "control-employees___ru",
    path: "employees",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-advertisings___ru",
    path: "expense/advertisings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/advertisings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-cleanings___ru",
    path: "expense/cleanings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/cleanings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-communals___ru",
    path: "expense/communals",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/communals/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-dry-washings___ru",
    path: "expense/dry-washings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/dry-washings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-expenses___ru",
    path: "expense/expenses",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "control-expense-subrents___ru",
    path: "expense/subrents",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/expense/subrents/index.vue").then(m => m.default || m)
  },
  {
    name: "control___ru",
    path: "",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/index.vue").then(m => m.default || m)
  },
  {
    name: "control-owners___ru",
    path: "owners",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/owners/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments___ru",
    path: "payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-online-payments___ru",
    path: "payments/online-payments",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/online-payments.vue").then(m => m.default || m)
  },
  {
    name: "control-payments-tax-system___ru",
    path: "payments/tax-system",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/payments/tax-system.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-rate-plans___ru",
    path: "rates/rate-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/rate-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-rates-restriction-plans___ru",
    path: "rates/restriction-plans",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/rates/restriction-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "control-reviews___ru",
    path: "reviews",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "control-schedule___ru",
    path: "schedule",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-backup___ru",
    path: "settings/backup",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/backup.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-communal-type___ru",
    path: "settings/communal-type",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/communal-type.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-content___ru",
    path: "settings/content",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/content.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-contribution-request___ru",
    path: "settings/contribution-request",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/contribution-request.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-expense-kind___ru",
    path: "settings/expense-kind",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/expense-kind.vue").then(m => m.default || m)
  },
  {
    name: "control-settings___ru",
    path: "settings",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-languages___ru",
    path: "settings/languages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/languages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-pages___ru",
    path: "settings/pages",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/pages.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-place-types___ru",
    path: "settings/place-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/place-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-service-types___ru",
    path: "settings/service-types",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/service-types.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-sources___ru",
    path: "settings/sources",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/sources.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-translation___ru",
    path: "settings/translation",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/translation.vue").then(m => m.default || m)
  },
  {
    name: "control-settings-xml-google___ru",
    path: "settings/xml-google",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/settings/xml-google.vue").then(m => m.default || m)
  },
  {
    name: "control-statistic___ru",
    path: "statistic",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/statistic/index.vue").then(m => m.default || m)
  },
  {
    name: "control-tasks___ru",
    path: "tasks",
    component: () => import("/var/www/control.barkar.apartments/front/pages/control/tasks/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexcJyTZpwLG1Meta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/uk",
    meta: indexcJyTZpwLG1Meta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexcJyTZpwLG1Meta || {},
    component: () => import("/var/www/control.barkar.apartments/front/pages/index.vue").then(m => m.default || m)
  }
]