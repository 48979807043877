import revive_payload_client_4sVQNw7RlN from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/control.barkar.apartments/front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/control.barkar.apartments/front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/var/www/control.barkar.apartments/front/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/var/www/control.barkar.apartments/front/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/control.barkar.apartments/front/.nuxt/sentry-client-config.mjs";
import plugin_vfCt6Q18Tc from "/var/www/control.barkar.apartments/front/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/control.barkar.apartments/front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/control.barkar.apartments/front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import alerts_4DHe7UerJq from "/var/www/control.barkar.apartments/front/plugins/alerts.ts";
import broadcast_client_M9PX6euSSn from "/var/www/control.barkar.apartments/front/plugins/broadcast.client.ts";
import currency_gntxDfkfZG from "/var/www/control.barkar.apartments/front/plugins/currency.ts";
import date_LyjcPwWLrp from "/var/www/control.barkar.apartments/front/plugins/date.ts";
import google_client_8m6xhHOjzo from "/var/www/control.barkar.apartments/front/plugins/google.client.ts";
import maska_client_D8wFsCvpJf from "/var/www/control.barkar.apartments/front/plugins/maska.client.ts";
import perfect_scrollbar_eDszbQJYAy from "/var/www/control.barkar.apartments/front/plugins/perfect-scrollbar.ts";
import tippy_client_WKoqvTKVZ4 from "/var/www/control.barkar.apartments/front/plugins/tippy.client.ts";
import toast_ysMjUcU7eJ from "/var/www/control.barkar.apartments/front/plugins/toast.ts";
import vue_easymde_client_a1tIVvYavg from "/var/www/control.barkar.apartments/front/plugins/vue-easymde.client.ts";
import vue3_apexcharts_client_rlebatt9PH from "/var/www/control.barkar.apartments/front/plugins/vue3-apexcharts.client.ts";
import vue3_json_excel_client_kZvvvCfyHK from "/var/www/control.barkar.apartments/front/plugins/vue3-json-excel.client.ts";
import vue3_popper_client_4mGDrhCm4m from "/var/www/control.barkar.apartments/front/plugins/vue3-popper.client.ts";
import vue3_quill_client_iXMSHkxwf2 from "/var/www/control.barkar.apartments/front/plugins/vue3-quill.client.ts";
import vueGoogleMaps_client_kxEpQqRip7 from "/var/www/control.barkar.apartments/front/plugins/vueGoogleMaps.client.ts";
import yupi18n_client_1FdO2pAmdl from "/var/www/control.barkar.apartments/front/plugins/yupi18n.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_vfCt6Q18Tc,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  alerts_4DHe7UerJq,
  broadcast_client_M9PX6euSSn,
  currency_gntxDfkfZG,
  date_LyjcPwWLrp,
  google_client_8m6xhHOjzo,
  maska_client_D8wFsCvpJf,
  perfect_scrollbar_eDszbQJYAy,
  tippy_client_WKoqvTKVZ4,
  toast_ysMjUcU7eJ,
  vue_easymde_client_a1tIVvYavg,
  vue3_apexcharts_client_rlebatt9PH,
  vue3_json_excel_client_kZvvvCfyHK,
  vue3_popper_client_4mGDrhCm4m,
  vue3_quill_client_iXMSHkxwf2,
  vueGoogleMaps_client_kxEpQqRip7,
  yupi18n_client_1FdO2pAmdl
]