export default defineNuxtPlugin((nuxtApp) => {
    const currency = {
        format: function (value, currency = 'UAH') {
            value = String(value); //convert to string for includes function
            if (value.includes('%')) { // % stop convert and return without changes - value not currency, but percent
                return value;
            }
            if (isNaN(value)) {
                return '0 ' + currency;
            }
            value = parseFloat(value);
            if (value === 0)
                return '0 ' + currency;
            if (value !== null)
                return value.toFixed().replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ' + currency;
        },
        currencyStrict: function (value) {
            value = String(value); //convert to string for includes function
            if (value.includes('%')) { // % stop convert and return without changes - value not currency, but percent
                return value;
            }
            if (isNaN(value)) {
                return '0 UAH';
            }
            value = parseFloat(value).toFixed(2);
            if (value === 0)
                return '0 UAH';
            if (value !== null)
                return value.replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' UAH';
        },
        round: function (value) {
            return Math.round(value)
        },
    }

    return {
        provide: {
            currency
        },
    };
});
