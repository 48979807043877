
export function handleApiSuccess(response: { status: number; headers: { get: Function }, _data: { message: string | undefined } }) {
    const toast = useToast()

    if (response.status == 200) {
        if (response._data.message) {
            toast.success(response._data.message)
        }
    }
}
