import Swal from "sweetalert2";

export default defineNuxtPlugin(() => {
    const { $i18n } = useNuxtApp()
    async function alertConfirm() {
        return Swal.fire({
            icon: 'warning',
            title: $i18n.t('alert.text.are you sure?'),
            text: $i18n.t('alert.text.You won\'t be able to revert this!'),
            showCancelButton: true,
            confirmButtonText: $i18n.t('action.delete'),
            cancelButtonText: $i18n.t('action.cancel'),
            padding: '2em',
            customClass: 'sweet-alerts',
        }).then(async (result) => {
            return result
        });
    }
    return {
        provide: {
            alertConfirm: alertConfirm,
        }
    }
})
