import {defineStore} from 'pinia'
import {array} from "yup";

export const useDataStore = defineStore('data', () => {
    const {locale} = useI18n();

    const workers = ref([])
    const tasks = ref([])
    const currencies = ref([])
    const languages = ref([])
    const incorrectBookings = ref([])
    const accommodations = ref([])
    const notPayedCommunal = ref([])
    const buildingTypes = ref([])
    const expenseTypes = ref([])
    const paymentTypes = ref([])
    const paymentGateways = ref([])
    const priceBaseCurrency = ref([])
    const bedTypes = ref([])
    const roomTypes = ref([])
    const bedTypesLoading = ref(false)
    const roomTypesLoading = ref(false)

    function addTask(task) {
        let index = tasks.value.findIndex(task => task.id === item.id);
        if (index > -1) {
            tasks.value.splice(index, 1, task)
        } else {
            tasks.value.push(task);
        }
    }

    async function getData() {
        await useApiFetch('get-data')
            .then((result) => {
                incorrectBookings.value = result.incorrectBookings
                expenseTypes.value = result.expenseTypes
                paymentGateways.value = result.paymentGateways
                buildingTypes.value = result.buildingTypes
            })
    }

    async function getNotPayedCommunal() {
        await useApiFetch('get-not-payed-communal')
            .then((result) => {
                notPayedCommunal.value = result.data
            })
    }

    async function getAccommodations() {
        await useApiFetch('accommodations')
            .then((result) => {
                accommodations.value = result.data
            })
    }

    async function getWorkers() {
        await useApiFetch('workers-all')
            .then((result) => {
                accommodations.value = result.data
            })
    }

    async function getTasks() {
        await useApiFetch('tasks')
            .then((result) => {
                tasks.value = result.data
            })
    }

    async function getBedTypes() {
        if (bedTypesLoading.value) return;
        bedTypesLoading.value = true;
        await useApiFetch('bed-types')
            .then((result) => {
                bedTypes.value = result
            })
    }

    async function getRoomTypes() {
        if (roomTypesLoading.value) return;
        roomTypesLoading.value = true;
        await useApiFetch('room-types')
            .then((result) => {
                roomTypes.value = result
            })
    }

    async function getCurrency() {
        return await useApiFetch('currencies')
            .then((result) => {
                currencies.value = result.data
            })
    }

    async function getLanguages() {
        await useApiFetch('languages')
            .then((result) => {
                languages.value = result.data
            })
    }

    async function initSystemRequires() {
        getLanguages();
        getWorkers();
        getData();
        getNotPayedCommunal();
    }

    return {
        getLanguages,
        getCurrency,
        getTasks,
        getWorkers,
        getAccommodations,
        getNotPayedCommunal,
        initSystemRequires,
        addTask,
        getData,
        getBedTypes,
        getRoomTypes,
        bedTypes,
        roomTypes,
        workers,
        tasks,
        currencies,
        languages,
        incorrectBookings,
        accommodations,
        notPayedCommunal,
        buildingTypes,
        expenseTypes,
        paymentTypes,
        paymentGateways,
        priceBaseCurrency,
    };
})
