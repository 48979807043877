import validate from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/www/control.barkar.apartments/front/middleware/auth.global.ts";
import sanctum_58auth_58global from "/var/www/control.barkar.apartments/front/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.global.js";
import manifest_45route_45rule from "/var/www/control.barkar.apartments/front/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  sanctum_58auth_58global,
  manifest_45route_45rule
]
export const namedMiddleware = {}