import {defineStore} from 'pinia'

export const useOrganizationStore = defineStore('organization', () => {
    const items = ref([])
    const item = ref(null)
    const loading = ref(false)

    async function fetch() {
        if (items.value.length) return
        loading.value = true;
        items.value = await useApiFetch('list-organizations')
        loading.value = false;
    }

    function close() {
        sidebar.value = false;
        item.value = false;
    }

    async function remove(id: number) {
        items.value = items.value.filter((item: { id: number }) => item.id !== id)
    }

    async function update(instance: any) {
        items.value = items.value.map((item: { id: number }) => item.id === instance.id ? instance : item);
    }

    async function add(instance: any) {
        items.value.unshift(instance)
    }

    async function fetchItem(id = null) {
        loading.value = true;
        item.value = await useApiFetch('read-organizations', {body: {id: id}})
        loading.value = false;
    }

    return {
        fetch,
        fetchItem,
        remove,
        update,
        add,
        close,
        loading,
        items,
        item,
    };
})
