export default defineAppConfig({
    icon: {
        size: '20px', // default <Icon> size applied
        class: 'icon', // default <Icon> class applied
        aliases: {
            'down': 'hugeicons:arrow-down-01',
            'settings': 'hugeicons:setting-07',
            'success': 'hugeicons:checkmark-circle-01',
            'alert': 'hugeicons:alert-02',
            'payments': 'hugeicons:money-receive-circle',
            'workers': 'hugeicons:location-user-03',
            'users': 'hugeicons:user-full-view',
            'owners': 'hugeicons:user-star-01',
            'devices': 'hugeicons:power-socket-01',
            'reviews': 'hugeicons:message-preview-01',
            'expenses': 'hugeicons:payment-success-02',
            'accommodation': 'hugeicons:house-02',
            'statistic': 'hugeicons:pie-chart-square',
            'schedule': 'hugeicons:time-schedule',
            'calendar': 'hugeicons:calendar-02',
            'task': 'hugeicons:task-02',
            'booking': 'hugeicons:bookmark-check-01',
            'next': 'hugeicons:arrow-right-01',
            'next2': 'hugeicons:arrow-right-double',
            'prev': 'hugeicons:arrow-left-01',
            'prev2': 'hugeicons:arrow-left-double',
            'nights': 'hugeicons:moon-02',
            'copyLink': 'hugeicons:copy-link',
            'print': 'hugeicons:printer',
            'add': 'hugeicons:add-square',
            'list': 'hugeicons:left-to-right-list-bullet',
            'time': 'hugeicons:alarm-clock',
            'edit': 'hugeicons:pencil-edit-02',
            'remove': 'hugeicons:delete-04',
            'close': 'hugeicons:cancel-01',
            'view': 'hugeicons:view',
            'view-hide': 'hugeicons:view-off',
            'info': 'hugeicons:information-square',
            'question': 'hugeicons:help-square',
            'id': 'hugeicons:text-number-sign',
            'uploadImage': 'hugeicons:image-upload',
            'pin': 'hugeicons:location-01',
            'guests': 'hugeicons:user-group',
            'check-in': 'hugeicons:location-check-01',
        }
    },
})
