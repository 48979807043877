import {defineStore} from 'pinia'

export const useListenerStore = defineStore('listener', () => {
    const auth = useAuthStore();

    const dragListener = ref([]);
    const newBookings = ref([]);
    const editedBookings = ref([]);
    const newPayments = ref([]);
    const editedCleanings = ref([]);
    const newCleanings = ref([]);
    const editedPayments = ref([]);

    function setDragTarget(target) {
        dragListener.value.push(target);
    }

    function stopDragging(target) {
        dragListener.value.splice(dragListener.value.indexOf(target), 1);
    }

    function isDragging(target) {
        return dragListener.value.includes(target);
    }

    const register = async (user) => {
        Echo.private(`task.${user.id}`)
            //PREDICT
            .listen(`.predict.finished`, (e) => {
                console.log(e, 'ds');
            })
            .listen(`.predict.changed`, (e) => {
                console.log(e, 'ds');
            })
            .listen(`.predict.started`, (e) => {
                console.log(e, 'ds');
            })
            //TRAIN
            .listen(`.train.started`, (e) => {

            })
            .listen(`.train.updated`, (e) => {

            })
            .listen(`.train.finished`, (e) => {

            });
        Echo.private(`user.${user.id}`)
            .listen(`.auth.verified`, () => {
                if (auth.user) {
                    auth.user.email_verified_at = new Date();
                }
            });
        Echo.private(`model.${user.id}`)
            .listen(`.model.created`, (e) => {

            })
    }

    return {
        register,
        isDragging,
        newBookings,
        editedBookings,
        newPayments,
        newCleanings,
        editedCleanings,
        editedPayments,
        stopDragging,
        setDragTarget,
        dragListener
    };
})
