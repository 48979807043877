
export function useToast() {
    return {
        warning: (message: string) => {
            useNuxtApp().$toast.warning(message)
        },
        error: (message: string) => {
            useNuxtApp().$toast.error(message)
        },
        success: (message: string) => {
            useNuxtApp().$toast.success(message)
        },
        info: (message: string) => {
            useNuxtApp().$toast.info(message)
        }
    }
}
