import {format, parse, parseISO, getDay, isSaturday, isSunday, eachDayOfInterval, addYears, addDays,
    startOfYear, isToday, isTomorrow, isYesterday } from 'date-fns'

import ru from 'date-fns/locale/ru'
import uk from 'date-fns/locale/uk'
import tr from 'date-fns/locale/tr'

const datefns: any = {
    parse,
    parseISO,
    getDay,
    isSaturday,
    isSunday,
    addYears,
    addDays,
    startOfYear,
    isToday,
    isTomorrow,
    isYesterday,
    eachDayOfInterval
}

const locales = {uk, ru, tr};
export default defineNuxtPlugin(() => {
    const { $i18n } = useNuxtApp()
    return {
        provide: {
            dateFns: datefns,
            parseDate: (date) => date ? parseISO(date) : date,
            format: (date, formate = 'y-MM-dd') => date ? format(date, formate, {'locale': locales[$i18n.locale._value]}) : date,
            formatString: (date, formate = 'y-MM-dd') => date || format(parseISO(date), formate, {'locale': locales[$i18n.locale._value]}),
            shortDate: (date) => format(date, 'y-MM-dd', {'locale': locales[$i18n.locale._value]}),
            addDays: addDays,
            addYears: addYears,
            isSunday: isSunday,
            isToday: isToday,
            eachDayOfInterval: eachDayOfInterval,
        }
    }
})
