export function handleApiError(response: {
    status: number;
    headers: { get: Function },
    _data: { message: string | undefined }
}) {
    const router = useRouter()
    const toast = useToast()
    const {$i18n} = useNuxtApp();

    if (import.meta.client) {
        if (response.status == 401) {
            toast.warning($i18n.t('auth.text.Invalid credentials. Please try again.'))
        }
        if (response.status == 404) {
            toast.warning($i18n.t('errors.wrong request url'))
        }
        if (response.status == 403) {
            toast.warning($i18n.t('errors.messages.You are unauthorized. Sign In first.'))
        }
        if (response.status == 500) {
            toast.warning(response._data.message)
        }
        if (response.status == 422 || response.status == 400) {
            toast.warning(response._data.message)
        }
    }
    if (response.status == 301) {
        router.replace('/')

    }
    if (response.status == 429) {
        let retryAfter = null;
        let message = '';
        if (response.headers) {
            retryAfter = response.headers.get('Retry-After')
        }
        if (!retryAfter) {
            message = $i18n.t('errors.messages.please wait to request new one')
        } else {
            message = $i18n.t('errors.messages.retry after', {retryAfter: $i18n.t('plurals.seconds', parseInt(retryAfter))})
        }
        toast.warning($i18n.t('errors.too many request'))
    }
}
